<template>
	<div class="mhome">
		<div class="mhome-wrap">
			<div class="mhome-head">
				<div class="user-item">
					<img class="item-pic" :src="userInfo.portrait" @error="avatarLoadError" alt="">
					<div class="item-block">
						<div class="name">{{ userInfo.userName }}</div>
						<div class="text">{{ userInfo.mobile }}</div>
						<div class="text">{{ $t('mobile.tags.fans') }} {{ userInfo.followers }}</div>
					</div>
				</div>
			</div>
			<div class="mhome-main">
				<div class="section">
					<router-link :to="{ name: 'mOrderList' }" class="section-head">
						<div class="item-label">{{ $t('mobile.user.myOrder') }}</div>
						<div class="item-block">{{ $t('mobile.actions.all') }}<i class="el-icon-arrow-right"></i></div>
					</router-link>
					<div class="order-nav">
						<router-link :to="{ name: 'mOrderList', params: { type: 'payment' }}" class="item">
							<div class="item-icon">
								<svg-icon icon-class="pay"></svg-icon>
								<div class="item-badge" v-if="statistics.pending>0">{{ statistics.pending }}</div>
							</div>
							<div class="item-name">{{ $t('mobile.actions.orderPayment') }}</div>
						</router-link>
						<router-link :to="{ name: 'mOrderList', params: { type: 'delivery' }}" class="item">
							<div class="item-icon">
								<svg-icon icon-class="send"></svg-icon>
								<div class="item-badge" v-if="statistics.accepted>0">{{ statistics.accepted }}</div>
							</div>
							<div class="item-name">{{ $t('mobile.actions.orderDelivery') }}</div>
						</router-link>
						<router-link :to="{ name: 'mOrderList', params: { type: 'receiving' }}" class="item">
							<div class="item-icon">
								<svg-icon icon-class="deliver"></svg-icon>
								<div class="item-badge" v-if="statistics.shipped>0">{{ statistics.shipped }}</div>
							</div>
							<div class="item-name">{{ $t('mobile.actions.orderReceiving') }}</div>
						</router-link>
						<router-link :to="{ name: 'mEvaluateList' }" class="item">
							<div class="item-icon">
								<svg-icon icon-class="evaluate"></svg-icon>
								<div class="item-badge" v-if="statistics.finished>0">{{ statistics.finished }}</div>
							</div>
							<div class="item-name">{{ $t('mobile.actions.orderEvaluate') }}</div>
						</router-link>
						<router-link :to="{ name: 'mRefundList', params: { type: 'refund' }}" class="item">
							<div class="item-icon">
								<svg-icon icon-class="refund"></svg-icon>
								<div class="item-badge" v-if="statistics.refund>0">{{ statistics.refund }}</div>
							</div>
							<div class="item-name">{{ $t('mobile.actions.orderRefund') }}</div>
						</router-link>
					</div>
				</div>
				<div class="section mhome-menu">
					<router-link :to="{ name: 'mAddressList' }" class="item">
						<div class="item-label">
							<svg-icon icon-class="round_location_fill" class="item-icon icon-address"></svg-icon>
							<span>{{ $t('mobile.user.address') }}</span>
						</div>
						<div class="item-block"><i class="el-icon-arrow-right"></i></div>
					</router-link>
				</div>
			</div>
			<div class="mhome-bottom">
				<footer-nav></footer-nav>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex'
	import footerNav from '@/components/mobile/footer'
	import { orderStatistics } from "@/api/mobile/order";
	
	export default {
		components: {
			footerNav
		},
		computed: {
			...mapGetters([
				'userInfo'
			])
		},
		data() {
			return {
				statistics: {}
			}
		},
		created () {
			this.getOrderStatistics()
		},
		methods: {
			avatarLoadError(e) {
				e.target.src = this.$imageErrorUser;
				e.target.style.objectFit = 'fill';
			},
			getOrderStatistics() {
				orderStatistics().then(response => {
					this.statistics = response.data
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.mhome-head {
		padding: 0.64rem 0.384rem;
		margin-bottom: 0.32rem;
		background: linear-gradient(#FF8002 0%, #FF5900 100%);
		.user-item {
			display: flex;
			align-items: center;
			.item-pic {
				flex-shrink: 0;
				width: 1.92rem;
				height: 1.92rem;
				border-radius: 50%;
				background-color: rgba(255, 255, 255, .5);
				box-shadow: 0 0 0 0.064rem rgba(255, 255, 255, .5) inset;
			}
			.item-block {
				flex: 1;
				width: 0;
				margin-left: 0.32rem;
				.name {
					color: #ffffff;
					font-size: 0.544rem;
				}
				.text {
					opacity: .8;
					color: #ffffff;
					font-size: 0.352rem;
					margin-top: 0.192rem;
				}
			}
		}
	}
	.mhome-main {
		padding: 0 0.256rem;
		.section {
			overflow: hidden;
			border-radius: 0.256rem;
			background-color: #ffffff;
			margin-bottom: 0.256rem;
			.section-head {
				display: flex;
				justify-content: space-between;
				line-height: 0.768rem;
				padding: 0.192rem 0.32rem;
				.item-label {
					color: #333333;
					font-size: 0.448rem;
					font-weight: 600;
				}
				.item-block {
					color: #999999;
					font-size: 0.384rem;
				}
			}
		}
		.order-nav {
			display: flex;
			padding: 0 0.32rem 0.16rem;
			.item {
				flex: 1;
				color: #333333;
				padding: 0.192rem 0;
				text-align: center;
				.item-icon {
					width: 0.896rem;
					margin: 0 auto;
					font-size: 0.896rem;
					background-size: 100% auto;
					background-repeat: no-repeat;
					background-position: center;
					position: relative;
				}
				.item-name {
					font-size: 0.32rem;
					line-height: 1em;
					margin-top: 0.192rem;
				}
				.item-badge {
					top: -0.128rem;
					right: 0;
					height: 0.48rem;
					line-height: 0.48rem;
					border-radius: 0.256rem;
					min-width: 0.48rem;
					padding: 0 0.128rem;
					color: #ffffff;
					font-size: 0.352rem;
					position: absolute;
					box-sizing: border-box;
					background-color: #ff5000;
					transform: translateX(50%);
				}
			}
		}
	}
	.mhome-menu {
		padding: 0 0.32rem;
		.item {
			color: #333333;
			display: flex;
			padding: 0.256rem 0;
			line-height: 0.768rem;
			justify-content: space-between;
			border-bottom: 1px solid #e5e5e5;
			.item-label {
				font-size: 0.416rem;
				display: flex;
				align-items: center;
				.item-icon {
					font-size: 0.768rem;
					background-size: 80% auto;
					background-repeat: no-repeat;
					background-position: center;
					margin-right: 0.192rem;
					&.icon-address {
						color: #ff5000;
					}
				}
			}
			.item-block {
				color: #999999;
				font-size: 0.448rem;
			}
			&:last-child {
				border-bottom: none;
			}
		}
	}
</style>